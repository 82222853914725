import { NodeHeaders } from 'server/NodeHeaders'
import { convertLocation } from 'functions/convertLocation'
import { definitions, operations } from 'api/generated/masked_ads'
import { fetchQueryApi } from 'api/fetchApi'

export const fetchAnnounceApi = (announceId: number, headers?: NodeHeaders) => {
  return fetchQueryApi<
    definitions['Ad'],
    operations['masked_ad_getOne']['parameters']['query']
  >(
    '/ad',
    {
      id: announceId,
    },
    {
      headers: { ...headers },
    }
  )
}

export const fetchAnnounceExtendedApi = (
  announcementId: number,
  params: definitions['AdSearchPreferences'],
  headers?: NodeHeaders
) => {
  const _location = convertLocation(params.location.location)

  const parameters = {
    id: announcementId,
    whoIs: params.whoIsSeeker,
    whoLooking: params.whoLookingFor,
    minAge: params.minAge,
    maxAge: params.maxAge,
    location: _location,
    similarLimit: 4,
    tags: params.tags.map(({ value }) => value),
  }

  return fetchQueryApi<
    definitions['AdExtended'],
    operations['masked_ad_extended_getOneExtended']['parameters']['query']
  >('/ad-extended', parameters, {
    headers: { ...headers },
  })
}
