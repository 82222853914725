import { definitions } from 'api/generated/masked_ads'
import { maximumAge, minimumAge, stepAge } from 'constants/age'

export const createFormFromAnnounce = (
  item: definitions['Ad'],
  tagsFromDefaultForm: definitions['AdTag'][]
): definitions['AdSearchPreferences'] => {
  const currentAge = item.ownerAge ? item.ownerAge : 0
  const _ageFrom = currentAge - stepAge
  const _ageTo = currentAge + stepAge

  const minAge = _ageFrom <= minimumAge ? minimumAge : _ageFrom
  const maxAge = _ageTo >= maximumAge ? maximumAge : _ageTo

  const _tags =
    item.tags && item.tags.length > 0 ? item.tags : tagsFromDefaultForm

  return {
    whoIsSeeker: item.whoLookingFor,
    whoLookingFor: item.whoIsOwner,
    tags: _tags,
    location: item.location,
    minAge,
    maxAge,
  }
}
